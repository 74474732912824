<template>
  <b-card :title="`${$t('edit_integration')}`">
  <router-link :to="{ path: $store.state.route.from.fullPath }" class="btn btn-sm btn-outline-secondary">{{ $t('back') }}</router-link>
    <div class="row">
      <div class="col-8">
        <div class="row">
          <div v-if="formGroup.groupId === ''" class="col">
            <div class="form-group">
              <label for="">Створити групу умов</label>
              <input type="text" class="form-control form-control-sm" v-model="formGroup.groupName">
            </div>
            <b-button size="sm" variant="primary" @click="createGroup">{{ $t('create') }}</b-button>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="">Група умов</label>
              <select class="form-control form-control-sm" v-model="formGroup.groupId" @change="selectGroup($event)">
                <option value=""> -- </option>
                <option v-for="group in groups" :value="group.id" :key="group.id">{{ group.name }}</option>
              </select>
            </div>
            <b-button v-if="formGroup.groupId !== ''" size="sm" variant="primary" @click="editGroup">{{ $t('edit') }}</b-button>
          </div>
        </div>
        <hr>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Метод доставки</th>
              <th>Метод оплати</th>
              <th>Статус оплати</th>
              <th>Формуєм чек</th>
              <th>Отримую кошти</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td><span>{{ item.deliveryName }}</span></td>
              <td><span>{{ item.paymentName }}</span></td>
              <td>{{ item.status }}</td>
              <td class="text-center">
                <b-checkbox v-model="item.checked" @change="setValue({ dId: item.deliveryId, pId: item.paymentId, status: item.status, checked: $event, receiveFunds: item.receiveFunds })"></b-checkbox>
              </td>
              <td class="text-center">
                <b-checkbox v-model="item.receiveFunds" @change="setFundsStatus({ dId: item.deliveryId, pId: item.paymentId, status: item.status, checked: item.checked, receiveFunds: $event })"></b-checkbox>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <h5>{{ $t('deposit') }}</h5>
              </td>
            </tr>
            <tr v-for="(item, index) in itemsSec" :key="index + 100">
              <td><span>{{ item.deliveryName }}</span></td>
              <td><span>{{ item.paymentName }}</span></td>
              <td>{{ item.status }}</td>
              <td class="text-center">
                <b-checkbox v-model="item.checked" @change="setValue({ dId: item.deliveryId, pId: item.paymentId, status: item.status, checked: $event, receiveFunds: item.receiveFunds })"></b-checkbox>
              </td>
              <td class="text-center">
                <b-checkbox v-model="item.receiveFunds" @change="setFundsStatus({ dId: item.deliveryId, pId: item.paymentId, status: item.status, checked: item.checked, receiveFunds: $event })"></b-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Users :groups="groups"/>
      <hr>
      <b-col sm="12">
        <b-row>
          <b-col sm="4">
            <h5>Групи умов, які належать до цієї інтеграції</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Назва умови</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="group in groups" :key="group.id">
                  <td>{{ group.name }}</td>
                  <td class="text-center">
                    <b-button size="sm" variant="outline-danger" pill @click="removeGroup(group.id)"><fa icon="times" /></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr>
            <!-- Cashier -->
            <h5>Додати нового касира</h5>
            <form @submit.prevent="addCashier" @keydown="form.onKeydown($event)">
              <b-row>
                <b-col sm="6">
                  <div class="form-group">
                    <label for="login">Логін касира<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="form.login" :class="{ 'is-invalid': form.errors.has('login') }">
                    <has-error :form="form" field="ogin" />
                  </div>
                  <div class="form-group">
                    <label for="password">Пароль касира<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }">
                    <has-error :form="form" field="password" />
                  </div>
                  <div class="form-group">
                    <label for="pincode">Пін-код касира<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="form.pincode" :class="{ 'is-invalid': form.errors.has('pincode') }">
                    <has-error :form="form" field="pincode" />
                  </div>
                  <div class="form-group">
                    <label for="licence_key">Ключ ліцензії каси<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="form.licence_key" :class="{ 'is-invalid': form.errors.has('licence_key') }">
                    <has-error :form="form" field="licence_key" />
                  </div>
                  <div v-for="item in deliveries" :key="item.id" class="form-group">
                    <label for="delivery">Метод доставки - <strong>{{ item.name }}</strong><span class="text-danger">*</span></label>
                    <select name="delivery" class="form-control" @change="setDeliveryKeyToCashier(item.id, $event)">
                      <option value=""> -- </option>
                      <option v-for="(k, index) in item.keys" :key="index" :value="k.key" :data-name="k.name"> {{ k.name }} - {{ k.key }} </option>
                    </select>
                  </div>
                </b-col>
                <b-col sm="6"></b-col>
              </b-row>
              <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
            </form>
          </b-col>
          <b-col sm="8">
            <h5>Зміна інтеграції</h5>
            <div class="form-group">
              <label for="name">Назва інтеграції<span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="integrationName">
            </div>
            <button type="button" class="btn btn-success" @click="editIntegration">{{ $t('edit') }}</button>
            <hr>
            <h5>Список касирів</h5>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>Логін касира</th>
                  <th>Пароль касира</th>
                  <th>Пін-скод касира</th>
                  <th>Ключ каси</th>
                  <td>Статус</td>
                  <th>Метод доставки/ключ відправника</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in cashiers" :key="item.id">
                  <td>{{ item.login }}</td>
                  <td>{{ item.password }}</td>
                  <td>{{ item.pincode }}</td>
                  <td>{{ item.licenceKey }}</td>
                  <td>
                    <b-checkbox v-model="item.status" @change="changeCashierStatus(item.id, $event)">
                      <span v-if="item.status">Активно</span>
                      <span v-else>Відключено</span>
                    </b-checkbox>
                  </td>
                  <td>
                    <ul v-if="item.deliveryKeys.length > 0" class="list-unstyled">
                      <li v-for="(key, index) in item.deliveryKeys" :key="index">
                        {{ key.name }}:
                        <p><strong>{{ key.username }}</strong>-{{ key.key }}</p>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <b-button size="sm" variant="outline-danger" pill @click="removeCashier(item.id)"><fa icon="times" /></b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <hr>
            <h5>Методи оплати в чеку</h5>
            <hr>
            <b-row>
              <b-col sm="3">
                <div class="form-group">
                  <label for="">{{ $t('payment_method') }}</label>
                  <select class="form-control form-control-sm" v-model="statusForm.paymentId">
                    <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.name }}</option>
                  </select>
                  <br>
                  <b-button size="sm" variant="primary" @click="addStatus">{{ $t('add') }}</b-button>
                </div>
              </b-col>
              <b-col sm="3">
                <div class="form-group">
                  <label for="">{{ $t('checkbox_status_check') }}</label>
                  <input type="text" class="form-control form-control-sm" v-model="statusForm.name">
                </div>
              </b-col>
            </b-row>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Метод оплати CRM</th>
                <th>Метод оплати Чекбокс</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in statuses" :key="item.id">
                <td>{{ item.payment }}</td>
                <td>{{ item.status_name }}</td>
                <td>
                  <b-button size="sm" variant="outline-danger" pill @click="removeStatus(item.id)"><fa icon="times" /></b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <hr>
            <h5>Налаштування часу формування чеків</h5>
            <div class="form-group">
              <label>Початок формування чеків</label>
              <b-form-timepicker size="sm" :hour12="false" locale="uk" v-model="settings.start_form_checks_time"></b-form-timepicker>
            </div>
            <div class="form-group">
              <label>Закриття зміни</label>
              <b-form-timepicker size="sm" :hour12="false" locale="uk" v-model="settings.close_shift_time"></b-form-timepicker>
            </div>
            <b-button size="sm" @click="saveSettings">{{ $t('save') }}</b-button>
          </b-col>
        </b-row>
      </b-col>
    </div>
  </b-card>
</template>
<script>
import Users from './common/users'
import axios from 'axios'
import Swal from 'sweetalert2'
import Form from 'vform'
export default {
  middleware: 'role:admin',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('checkbox') }
  },
  components: {
    Users
  },
  data: () => ({
    items: [],
    itemsSec: [],
    formGroup: {
      groupName: '',
      groupId: ''
    },
    form: new Form({
      login: '',
      password: '',
      pincode: '',
      licence_key: '',
      deliveryKeys: []
    }),
    statusForm: {
      paymentId: '',
      name: ''
    },
    settings: {
      id: null,
      start_form_checks_time: '00-00-00',
      close_shift_time: '00-00-00'
    },
    integrationName: '',
    cashiers: null,
    statuses: null,
    payments: null,
    groups: null,
    values: [],
    urlParams: {},
    deliveries: null
  }),
  mounted () {
    this.getList()
    this.getCheckboxGroups()
    this.getIntegration()
    this.getPayments()
    this.getDeliveriesData()
  },
  methods: {
    async getDeliveriesData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'deliveries/keys').then(resp => {
       this.deliveries = resp.data
      })
    },
    async saveSettings () {
      const integrationId = this.$route.params.id
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'checkbox/settings', {
        id: this.settings.id !== null ? this.settings.id : '',
        start_form_checks_time: this.settings.start_form_checks_time,
        close_shift_time: this.settings.close_shift_time,
        integrationId: integrationId
      }).then(resp => {
        if (resp.data.status) {
          Swal.fire({
            title: 'Успіх!',
            text: 'Налаштування збережені',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          this.getIntegration()
        }
      })
    },
    async getPayments () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'payments').then(resp => {
        if (resp.data && resp.data.data) {
          this.payments = resp.data.data.map(p => {
            return { id: p.id, name: p.name }
          })
        }
      })
    },
    async addStatus () {
      const integrationId = this.$route.params.id
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      this.statusForm.integrationId = integrationId
      await axios.post(apiUrl + 'checkbox/statuses', this.statusForm).then(resp => {
        if (resp.data && resp.data.status) {
          this.formStatus = {
            paymentId: '',
            name: ''
          }
          this.getIntegration()
        }
      })
    },
    async removeStatus (id) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.delete(apiUrl + 'checkbox/statuses/' + id).then(resp => {
        if (resp.data && resp.data.status) {
          this.getIntegration()
        }
      })
    },
    // group
    selectGroup (e) {
      const val = e.target.value
      this.urlParams['groupId'] = val
      this.getList()
    },
    async getIntegration () {
      const integrationId = this.$route.params.id
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'checkbox/integration/' + integrationId).then(resp => {
        // this.form.fill(resp.data.)
        this.integrationName = resp.data.name
        this.cashiers = resp.data.cashiers
        if (resp.data.settings !== null) {
          this.settings = resp.data.settings
        }
        if (resp.data.statuses !== null) {
          this.statuses = resp.data.statuses
        }
      })
    },
    async getList () {
      const params = this.buildQuery() !== '' ? '?' + this.buildQuery() : ''
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'checkbox/list' + params).then(resp => {
        if (resp && resp.data) {
          const arr = []
          const arr2 = []
          resp.data.forEach(item => {
            if (item.deliveryName === 'Завдаток') {
              arr2.push(item)
            } else {
              arr.push(item)
            }
          })
          this.items = arr.sort((a, b) => a.deliveryId - b.deliveryId)
          this.itemsSec = arr2.sort((a, b) => a.deliveryId - b.deliveryId)
        }
      })
    },
    async getCheckboxGroups () {
      const integrationId = this.$route.params.id
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'checkbox/groups/' + integrationId).then(resp => {
        if (resp.data && resp.data) {
          this.groups = resp.data
        }
      })
    },
    async createGroup () {
      const integrationId = this.$route.params.id
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'checkbox/group/' + integrationId, { name: this.formGroup.groupName, values: this.values }).then(resp => {
        if (resp.data && resp.data.status) {
          this.getCheckboxGroups()
          this.getList()
          this.formGroup.groupName = ''
          this.values = []
        }
      })
    },
    async editGroup () {
      const checked = []
      this.items.forEach(i => {
        if (i.checked) {
          checked.push(i)
        }
      })
      this.itemsSec.forEach(i => {
        if (i.checked) {
          checked.push(i)
        }
      })
      const integrationId = this.$route.params.id
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'checkbox/group/' + this.formGroup.groupId + '/edit', { integrationId: integrationId, values: checked }).then(resp => {
        if (resp.data && resp.data.status) {
          this.getCheckboxGroups()
          this.getList()
          this.values = []
        }
      })
    },
    async removeGroup (id) {
      if (confirm('Ви точно хочете видалити?')) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.delete(apiUrl + 'checkbox/group/' + id).then(resp => {
          if (resp.data.status) {
            this.getCheckboxGroups()
          } else {
            Swal.fire({
              icon: 'danger',
              title: 'Помилка',
              text: resp.data.msg,
              reverseButtons: true,
              confirmButtonText: this.$t('ok'),
              cancelButtonText: this.$t('cancel')
            })
          }
        })
      }
    },
    setValue (param) {
      param.integrationId = Number.parseInt(this.$route.params.id)
      if (this.values.length > 0) {
        if (param.checked) {
          this.values.push(param)
        } else {
          const index = this.values.findIndex(v => v.dId === param.dId && v.pId === param.pId && v.status === param.status)
          this.values.splice(index, 1)
        }
      } else { // empty
        this.values.push(param)
      }
    },
    setFundsStatus (param) {
      param.integrationId = Number.parseInt(this.$router.params.id)
      if (this.values.length > 0) {
        if (param.receiveFunds) {
          this.values.push(param)
        } else {
          const index = this.values.findIndex(v => v.dId === param.dId && v.pId === param.pId && v.status === param.status)
          this.values.splice(index, 1)
        }
      } else {
        this.values.push(param)
      }
    },
    async addCashier () {
      this.form.integrationId = Number.parseInt(this.$route.params.id)
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.post(apiUrl + 'checkbox/cashier').then(resp => {
        if (resp.data.status) {
          this.form.reset()
          this.getIntegration()
        }
      })
    },
    async removeCashier (id) {
      if (confirm('Ви точно хочете видалити?')) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.delete(apiUrl + 'checkbox/cashier/' + id).then(resp => {
          if (resp.data.status) {
            this.getIntegration()
          }
        })
      }
    },
    async changeCashierStatus (id, val) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'checkbox/cashier/status', { id: id, status: val }).then(resp => {
        if (resp.data.status) {
          this.getIntegration()
        }
      })
    },
    setDeliveryKeyToCashier (deliveryId, e) {
      const key = e.target.value
      const name = e.target.options[e.target.selectedIndex].dataset.name
      if (key !== '') {
        this.form.deliveryKeys.push({ deliveryId: deliveryId, key: key, username: name })
      } else {
        const index = this.form.deliveryKeys.findIndex(d => d.deliveryId === deliveryId)
        this.form.deliveryKeys.splice(index, 1)
      }
    },
    // update integration name
    async editIntegration () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'checkbox/integration/' + this.$route.params.id, { name: this.integrationName }).then(resp => {
        if (resp.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Успіх!',
            text: 'Дані оновлено',
            reverseButtons: true,
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel')
          })
          this.getIntegration()
        }
      }).catch(error => {
        this.form.errors.set(error.response.data.error.errors)
      })
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  }
}
</script>
